<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newSalaryMaster'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head max-width-900"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Edit Salary Master
            </h5>
            <a
              class="close"
              title="Close"
              @click="$store.dispatch('modalClose', 'addNewItem')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-4 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Employee *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.employee_id"
                              name="employee_id"
                              id="employee"
                              disabled
                              class="form-control"
                            >
                             <option :value="formData.employee_id">{{formData.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Month *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select name="" id="" class="form-control" v-model="formData.month" @change="attendenceInfo(formData.emp_id)" disabled>
                              <option value="" disabled>Select Month</option>
                              <option value="1">January</option>
                              <option value="2">February</option>
                              <option value="3">March</option>
                              <option value="4">April</option>
                              <option value="5">May</option>
                              <option value="6">June</option>
                              <option value="7">July</option>
                              <option value="8">August</option>
                              <option value="9">September</option>
                              <option value="10">Octobe</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </select>
                          </div>
                        </div>
                         <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Year *</div>
                          <div class="col-md-7 ml-0 pl-0">
                           <input type="text" name="year" id="year" v-model="formData.year" disabled>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Attendence Information</label>
                      <div
                        class="group-attribute-container"
                        v-if="dataLists7"
                      >
                        <div class="row mb-1">
                          <div class="col-md-5 mr-0 pr-0">Total Month Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{ dataLists7.total_days }}
                          </div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-md-5 mr-0 pr-0">Present Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{ dataLists7.present }}
                          </div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-md-5 mr-0 pr-0">Holidays Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{ dataLists7.holiday }}
                          </div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-md-5 mr-0 pr-0">Absent Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{ dataLists7.absent }}
                          </div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-md-5 mr-0 pr-0">Half Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{ dataLists7.half }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Late Days</div>
                          <div class="col-md-7 ml-0 pl-0 pt-1">
                            : {{ dataLists7.late }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="group-attribute-container"
                        v-else
                      >
                      <span> No Attendence Found</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Deduction</label>
                      <div class="group-attribute-container">
                        <div
                          class="row mb-2"
                          v-for="(extra_field, index) in extra_fields"
                          :key="index"
                        >
                          <div
                            v-if="extra_field.type == '1'"
                            class="col-md-5 mr-0 pr-0"
                          >
                            {{ extra_field.title }}
                          </div>
                          <div
                            class="col-md-7 ml-0 pl-0"
                            v-if="extra_field.type == '1'"
                          >
                            <input
                              v-if="extra_field.amount"
                              :value="extra_field.amount"
                              :id="`ext-${extra_field.slug}`"
                              class="form-control"
                              step="any"
                              placeholder="0.00"
                              type="number"
                              @blur="extraFields({ ...extra_field })"
                            />
                            <input
                              v-else
                              :id="`ext-${extra_field.slug}`"
                              class="form-control"
                              step="any"
                              placeholder="0.00"
                              type="number"
                              @blur="extraFields({ ...extra_field })"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Addition</label>
                      <div class="group-attribute-container">
                        <div
                          class="row mb-2"
                          v-for="(extra_field, index) in extra_fields"
                          :key="index"
                        >
                          <div
                            v-if="extra_field.type == '0'"
                            class="col-md-5 mr-0 pr-0"
                          >
                            {{ extra_field.title }}
                          </div>
                          <div
                            class="col-md-7 ml-0 pl-0"
                            v-if="extra_field.type == '0'"
                          >
                            <input
                              v-if="extra_field.amount"
                              :value="extra_field.amount"
                              :id="`ext-${extra_field.slug}`"
                              class="form-control"
                              step="any"
                              type="number"
                              placeholder="0.00"
                              @blur="extraFields({ ...extra_field })"
                            />
                            <input
                              v-else
                              :id="`ext-${extra_field.slug}`"
                              class="form-control"
                              step="any"
                              placeholder="0.00"
                              type="number"
                              @blur="extraFields({ ...extra_field })"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Tax</label>
                      <div class="group-attribute-container">
                        <div
                          class="row mb-2"
                          v-for="(tax, index) in tax_fields"
                          :key="index"
                        >
                          <div
                            class="col-md-5 mr-0 pr-0"
                          >
                            {{ tax.title }}
                          </div>
                          <div
                            class="col-md-7 ml-0 pl-0"
                          >
                            <input
                              v-if="tax.amount"
                              :value="tax.amount"
                              :id="`ext-${tax.slug}`"
                              class="form-control"
                              step="any"
                              placeholder="0.00"
                              type="number"
                              @blur="taxFields({ ...tax })"
                            />
                            <input
                              v-else
                              :id="`ext-${tax.slug}`"
                              class="form-control"
                              step="any"
                              placeholder="0.00"
                              type="number"
                              @blur="taxFields({ ...tax })"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Calculations</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Basic Salary</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.salary"
                              type="text"
                              name="expense_amount"
                              class="form-control"
                              placeholder="0.00"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Gross Salary</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.gross_salary"
                              type="text"
                              name="expense_amount"
                              class="form-control"
                              placeholder="0.00"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Net Salary</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.net_salary"
                              type="text"
                              name="expense_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Advance Due</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.advance_due"
                              type="text"
                              name="expense_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Advance Deduct</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.advance_deduct"
                              type="number"
                              step="any"
                              name="expense_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              :max="formData.advance_due"
                              min="0"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Payable Salary</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.payable_salary"
                              type="text"
                              name="paid_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary"
                  :disabled="disableSubmitButton"
                >
                  {{ disableSubmitButton ? "Submitting..." : "Submit" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "../Services/Services";
export default {
  data() {
    return {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      extra_fields: [],
      years:[],
      tax_fields:[],
      attendences: [],
      dataSets: "",
      disableSubmitButton: false,
      formData: {
        id: "",
        employee_id: "",
        month: this.salary_month,
        year:this.salary_year,
        salary: 0,
        gross_salary: 0,
        extra_fields: [],
        tax_fields:[],
        tds: 0,
        net_salary: 0,
        advance_due: 0,
        advance_deduct: 0,
        payable_salary: 0,
      },
    };
  },
  props: ["salary_month","salary_year"],
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists2",
      "dataLists3",
      "dataLists4",
      "dataLists5",
      "dataLists6",
      "dataLists7",
      "modalId",
      "modalState",
      "dataId",
      "modalMode",
    ]),
  },
  methods: {
    extraFields(details) {
      let index = this.formData.extra_fields.findIndex(
        (x) => x.slug === details.slug
      );
      if (index >= 0) {
        this.formData.extra_fields[index].amount = document.getElementById(
          `ext-${details.slug}`
        ).value;
      } else {
        this.formData.extra_fields.push({
          title: details.title,
          slug: details.slug,
          type: details.type,
          amount: document.getElementById(`ext-${details.slug}`).value,
        });
      }
      this.calculateGrossSalary();
    },
    taxFields(details) {
      let index = this.formData.tax_fields.findIndex(
        (x) => x.slug === details.slug
      );
      if (index >= 0) {
        this.formData.tax_fields[index].amount = document.getElementById(
          `ext-${details.slug}`
        ).value;
      } else {
        this.formData.tax_fields.push({
          title: details.title,
          slug: details.slug,
          amount: document.getElementById(`ext-${details.slug}`).value,
        });
      }
      this.calculateGrossSalary();
    },
    calculateGrossSalary() {
      let total = this.parseDigit(this.formData.salary);
      if (this.formData.extra_fields != null) {
        if (this.formData.extra_fields.length == 0) {
          this.formData.gross_salary = this.parseDigit(this.formData.salary);
        } else {
          this.formData.extra_fields.forEach((extra) => {
            if (parseInt(extra.type) == 0) {
              total =
               this.parseDigit( parseFloat(total) +
                (extra.amount === "" ? 0 : parseFloat(extra.amount)));
            } else {
              total =
                this.parseDigit(parseFloat(total) -
                (extra.amount === "" ? 0 : parseFloat(extra.amount)));
            }
          });
          this.formData.gross_salary = this.parseDigit(total);
        }
      } else {
        this.formData.gross_salary = this.parseDigit(total);
      }
      let gross_total = this.parseDigit(this.formData.gross_salary);
      if (this.formData.tax_fields != null) {
        if (this.formData.tax_fields.length == 0) {
          this.formData.net_salary = this.parseDigit(this.formData.gross_salary);
        } else {
          this.formData.tax_fields.forEach((extra) => {
            if (parseInt(extra.type) == 0) {
              gross_total =
                this.parseDigit(parseFloat(gross_total) +
                (extra.amount === "" ? 0 : parseFloat(extra.amount)));
            } else {
              gross_total =
                this.parseDigit(parseFloat(gross_total) -
                (extra.amount === "" ? 0 : parseFloat(extra.amount)));
            }
          });
          this.formData.net_salary = this.parseDigit(gross_total);
        }
      } else {
        this.formData.net_salary = this.parseDigit(gross_total);
      }
      // this.formData.net_salary =
      //   parseFloat(this.formData.gross_salary) - parseFloat(this.formData.tds?this.formData.tds:0);
      this.formData.payable_salary =
        this.parseDigit(parseFloat(this.formData.net_salary) -
        parseFloat(this.formData.advance_deduct?this.formData.advance_deduct:0));
    },
    clearData() {
      this.formData.id = "";
      this.formData.employee_id = "";
      this.formData.month = "";
      this.formData.year ="";
      this.formData.salary = 0;
      this.formData.gross_salary = 0;
      this.formData.net_salary = 0;
      this.formData.extra_fields = [];
      this.formData.tds = 0;
      this.formData.advance_due = 0;
      this.formData.advance_deduct = 0;
      this.formData.payable_salary = 0;
      //this.$store.commit("getData3", `api/settings/employee/salary-master`);
    },
    submit() {
      this.disableSubmitButton = true;
      if(this.formData.id){
        Services.updateSalaryMaster(this.formData, this.formData.id)
          .then((res) => {
            this.disableSubmitButton = false;
            this.$emit("parent-event");
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
          })
          .catch((err) => {
            this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              let error = err.response.data.error_message;
              this.setNotification(error);
            }
          });
      }else{
           Services.createSalaryMaster(this.formData)
          .then((res) => {
            this.disableSubmitButton = false;
            this.$emit("parent-event");
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
          })
          .catch((err) => {
            this.disableSubmitButton = false;
            if (err.response.status == 422) {
              let error = Object.values(err.response.data.errors).flat();
              this.setNotification(error);
            } else {
              let error = err.response.data.error_message;
              this.setNotification(error);
            }
          });
      }
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    attendenceInfo(id) {
      let param = {
        month: this.formData.month,
        year: new Date().getFullYear(),
        id: id,
      };
      Services.getSalaryMasterEmployeeAttendence(param)
        .then((res) => {
          this.$store.commit("setDataLists7", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    modalId(value) {
      this.clearData();
      let id = this.dataId;
      if (value == "newSalaryMaster") {
        let details = this.dataLists6.find(function (data) {
          return data.emp_id == id;
        });
        this.formData = { ...details };
        this.formData.employee_id = details.emp_id;
        this.formData.salary = details.salary;
        this.formData.month = this.salary_month;
        this.formData.year = this.salary_year;
        this.formData.advance_due = details.advance;
        this.attendenceInfo(details.emp_id);
        if (details.extra_fields != null) {
          this.extra_fields = JSON.parse(details.extra_fields);
          this.formData.extra_fields = this.extra_fields;
        } else {
          Services.getSalaryMasterExtraFields().then(res=>{
            this.formData.extra_fields = [];
            let datas = res.data.data;
            datas.forEach(element => {
              this.formData.extra_fields.push({
                title: element.title,
                slug: element.slug,
                type: element.type,
                amount: 0,
              });
            });
            this.extra_fields = datas;
          }).catch(err=>{
            console.log(err);
          });
          this.formData.extra_fields = [];
        }
        if (details.tax_fields != null) {
          this.tax_fields = JSON.parse(details.tax_fields);
          this.formData.tax_fields = this.tax_fields;
        } else {
          Services.getSalaryMasterTaxExtraFields().then(res=>{
            this.formData.tax_fields = [];
            let datas = res.data.data;
            datas.forEach(element => {
              this.formData.tax_fields.push({
                title: element.title,
                slug: element.slug,
                amount: 0,
              });
            });
            this.tax_fields = datas;
          }).catch(err=>{
            console.log(err);
          });
          this.formData.tax_fields = [];
        }
        this.calculateGrossSalary();
      }
    },
    "formData.tds"(value) {
      if (value) {
        this.formData.net_salary =
          parseFloat(this.formData.gross_salary) - parseFloat(value);
        this.formData.payable_salary =
          parseFloat(this.formData.net_salary) -
          parseFloat(this.formData.advance_deduct);
      }
    },
    "formData.advance_deduct"(value) {
      if (value) {
        this.formData.payable_salary =
          parseFloat(this.formData.net_salary) - parseFloat(value);
      }
    },
    dataLists3(value) {
      if (value.length != 0) {
        this.extra_fields = value;
      }
    },
  },
};
</script>
