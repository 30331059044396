<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Human Resource</a></li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Salary Master
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Salary Master</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <!-- <button
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'newSalaryMaster',
              mode: 'create',
            })
          "
        >
          New Setup
        </button> -->
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select
            name="size"
            v-model="params.pageIndex"
            @change="searchFilter"
            class="mr-2 mg-t-5"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="">All</option>
          </select>
          <select
            v-model="params.branch"
            class="filter-input mr-2 mg-t-5"
            @change="searchFilter"
          >
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in payrollElements.filter_branchs"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <SearchDayMonthComponent
            :showDay="false"
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            class="float-right"
            placeholder="Search..."
            v-model="params.searched"
            @keyup="searchFilter()"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Emp. ID</th>
              <th class="wd-20p">Employee Name</th>
              <th class="wd-15p">Designation</th>
              <th class="wd-10p">Basic Salary</th>
              <th class="wd-10p">Net Salary</th>
              <th class="wd-10p">Payable Salary</th>
              <th class="wd-10p">Status</th>
              <th
                class="wd-10p text-center table-end-item"
                v-if="
                  checkIfAnyPermission([
                    'view_salary_master',
                    'edit_salary_master',
                  ])
                "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="!loading && salaryMasterDatas.length > 0">
            <tr v-for="(master, index) in salaryMasterDatas" :key="index">
              <th scope="row" class="table-start-item">
                {{ pageSerialNo + index }}.
              </th>
              <td>{{ master.emp_code }}</td>
              <td>{{ master.name }}</td>
              <td>{{ master.designation }}</td>
              <td>{{ parseDigitForSlip(master.salary) }}</td>
              <td>{{ master.net_salary?parseDigitForSlip(master.net_salary):'-' }}</td>
              <td>{{ master.payable_salary?parseDigitForSlip(master.payable_salary):'-' }}</td>
              <td>{{master.id?'Created':'Not created'}}</td>
              <td
                class="text-center table-end-item"
                v-if="
                  checkIfAnyPermission([
                    'view_salary_master',
                    'edit_salary_master',
                  ])
                "
              >
                <a
                title="View Salary"
                  href="javascript:;"
                  v-if="checkSinglePermission('view_salary_master')"
                  class="mr-3"
                >
                  <i
                    class="fas fa-eye tx-success"
                    @click="view('salaryMasterView', master.emp_id)"
                  ></i>
                </a>
                <a
                  href="javascript:;"
                  title="Edit Salary"
                  v-if="checkSinglePermission('edit_salary_master') && master.id"
                >
                  <i
                    class="fa fa-edit"
                    @click="edit('newSalaryMaster', master.emp_id)"
                  ></i>
                </a>
                <a
                  href="javascript:;"
                  title="Create Salary"
                  v-if="checkSinglePermission('edit_salary_master') && !master.id"
                >
                  <i
                    class="fa fa-plus"
                    @click="edit('newSalaryMaster', master.emp_id)"
                  ></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" class="text-center">{{ error_message }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && salaryMasterDatas.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
        <Create :salary_month="params.month+1" :salary_year="params.year" @parent-event="getData()"></Create>
        <ViewSlip :salary_month="params.month" :salary_year="params.year"></ViewSlip>
      </div>
    </div>
  </div>
</template>
<script>
import ViewSlip from "./Slip";
import Create from "./create";
import Services from "../Services/Services";
import { mapGetters } from "vuex";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import Paginate from "vuejs-paginate";
import _ from "lodash";
export default {
  data() {
    return {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      loading: false,
      error: false,
      error_message: "",
      page: 1,
      totalPageCount: 0,
      pageSerialNo: 1,
      params: {
        pageIndex: 10,
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        searched: "",
        offset: 0,
      },
      search: {
        month: "",
        branch: "",
      },
      salary_month: "",
    };
  },
  components: {
    Create,
    ViewSlip,
    SearchDayMonthComponent,
    Paginate,
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists7",
      "dataLists6",
      "dataLists8",
      "removeList",
      "pagination",
      "eventMessage",
      "modalId",
      "dataLists6",
    ]),
    ...mapGetters("employees", ["salaryMasterDatas","payrollElements"]),
  },
  methods: {
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.pageIndex + 1;
      }
      this.params.offset = (page - 1) * this.params.pageIndex;
      this.getData();
    },
    monthDayClicked(value) {
      if (value) {
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year;
      }
      //make api call
      this.getData();
    },
    current_month() {
      var dateObj = new Date();
      this.salary_month =
        dateObj.getUTCFullYear() +
        "-" +
        ("0" + dateObj.getMonth() + 1).slice(-2);
      this.getData();
    },
    getMonth() {
      this.getData();
    },
    edit(modal, id) {
      this.$store.commit("setDataLists6", this.salaryMasterDatas);
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    view(modal, id) {
      this.$store.commit("setDataLists6", this.salaryMasterDatas);
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "view" });
    },
    getData() {
      this.loading = true;
      Services.getSalaryMasterList(this.params)
        .then((res) => {
          this.$store.commit(
            "employees/setSalaryMasterDatas",
            res.data.data.data
          );
          this.getElements();
          this.loading = false;
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          this.$store.commit("employees/setSalaryMasterDatas", []);
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            }else if(err.response.data.error_message == "You cannot update future salaries"){
              this.error_message = "You cannot update future salaries..";
            }
             else {
              this.error_message = "Error fetching data from the server.";
            }
          }
          let error = err.response.data.error_message;
          this.setNotification(error);
        });
    },
    getElements(){
       Services.getPayrollElements().then(res=>{
        this.$store.commit("employees/setPayrollElements",res.data.data);
      }).catch(err=>{ 
          let error = err.response.data.error_message;
          this.setNotification(error);
      });
    },
    getMonthlyAttendence() {
      Services.getMonthlyAttendence(this.params)
        .then((res) => {
          this.$store.commit("setDataLists7", res.data.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchFilter: _.debounce(function () {
      this.loading = true;
      this.page = 1;
      this.params.offset = 0;
      this.pageSerialNo = 1;
      Services.getSalaryMasterList(this.params)
        .then((res) => {
          this.loading = false;
          this.$store.commit(
            "employees/setSalaryMasterDatas",
            res.data.data.data
          );
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          this.$store.commit("employees/setSalaryMasterDatas", []);
          let error = err.response.data.error_message;
          this.setNotification(error);
        });
    }, 1000),
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
  },
  mounted() {
    this.current_month();
    //this.$store.commit("getData8", `api/salary-payroll/create`);
  },
};
</script>
<style scoped></style>
